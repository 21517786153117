<template>
  <div class="container-warp">
    <div class="top-box">
      <back-title pageType="planEdit">编辑计划</back-title>
      <div class="top-box-info">
        <div class="top-box-info-left">
          <img v-if="!dataInfo.cover" src="@/assets/img/Course.png" alt="">
          <img
            v-else
            :src="dataInfo.cover"
            alt=""
          />
          <div class="top-box-info-left-xx">
            <div class="top-box-info-left-bt">
              {{ dataInfo?.planName }}<span v-if=" dataInfo?.typeValue">{{ dataInfo?.typeValue }}</span>
            </div>
            <div class="top-box-info-left-kc">
              课程编号：{{ dataInfo?.studyPlanCode }}
            </div>
            <div class="top-box-info-left-mc">
              {{ dataInfo?.createName }}创建于{{ dataInfo?.createTime }}
            </div>
          </div>
        </div>
        <div class="top-box-info-right" v-if="dataInfo?.status == 1">
          <el-button class="add-group-btn" :style="{'--lock-btn': $store.state.settings.theme}" @click="mastEndClass(2)"
            >结束计划</el-button
          >
          <el-button type="primary" @click="studyProcess">学习进度</el-button>
        </div>
        <div class="top-box-info-right" v-if="dataInfo?.status == 0">
          <el-button class="add-group-btn" :style="{'--lock-btn': $store.state.settings.theme}" @click="mastEndClass(1)"
            >立即开始</el-button
          >
          <el-button type="primary" @click="deletePlan" v-if="dataInfo.status!=2">删除计划</el-button>
        </div>
        <div class="top-box-info-right" v-if="dataInfo?.status == 2">
          <el-button class="add-group-btn" :style="{'--lock-btn': $store.state.settings.theme}" @click="examRecord" v-if="dataInfo.examSum>=1">考试记录</el-button>
          <el-button type="primary" @click="deletePlan" v-if="dataInfo.status!=2">删除计划</el-button>
        </div>
      </div>
    </div>
    <div class="bottom-box">
      <el-tabs class="bottom-tab-box" v-model="activeName"   @tab-click="_tabChange">
        <el-tab-pane label="基本信息" name="1">
          <basic-info
          :dataInfo="dataInfo"
          @updatedDetail="updatedDetail"
          />
        </el-tab-pane>
        <el-tab-pane label="课程安排" name="2">
          <course-arrangement
          :detailId="planId"
           :dataInfo="dataInfo"
           />
        </el-tab-pane>
        <el-tab-pane label="受训学员" name="3">
          <trainee :dataInfo="dataInfo"></trainee>
        </el-tab-pane>
        <el-tab-pane label="设置" name="4">
          <setting
          :dataInfo="dataInfo"
          @updatedDetail="updatedDetail"
          ></setting>
        </el-tab-pane>
        <el-tab-pane label="操作记录" name="5">
          <operation-record
          ref="operationRecord"
          :dataInfo="dataInfo"
          ></operation-record>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import backTitle from '@/components/backTitle/index.vue'
import basicInfo from '../components/basicInfo.vue'
import trainee from '../components/trainee.vue'
import setting from '../components/setting.vue'
import courseArrangement from '../components/courseArrangement.vue'
import operationRecord from '../components/operationRecord.vue'
import {
  studyPlanDetailApi,
  updateStatusApi,
  removeTrStudyPlanApi
} from '@/api/study.js'
export default {
  components: {
    backTitle,
    basicInfo,
    trainee,
    setting,
    courseArrangement,
    operationRecord
  },
  data () {
    return {
      activeName: '1',
      dataInfo: {}, // info
      planId: null // 计划id
    }
  },
  created () {
    if (this.$route.query.id) {
      this.planId = this.$route.query.id
    }
    if (this.$route.query.status) {
      this.activeName = this.$route.query.status
    }
    this.getDetailInfo()
  },
  methods: {
    getTabItem (type) {
      switch (type) {
        case 'detail':
          this.activeName = '1'
          break
        case 'class':
          this.activeName = '2'
          break
        default:
          break
      }
    },
    updatedDetail () {
      this.getDetailInfo()
    },
    // 获取详情
    getDetailInfo () {
      studyPlanDetailApi({ id: this.planId }).then((res) => {
        if (res.code === 200) {
          this.dataInfo = res.data
          if (res.data.status == 1) {
            this.$message({
              message: '当前学习正在进行中，更新后将直接生效',
              showClose: true,
              type: 'info',
              iconClass: 'iconClass',
              customClass: 'customClass',
              duration: 3000
            })
          }
          if (this.dataInfo.expiredFlag == 1) {
            this.$set(this.dataInfo, 'switchValue', true)
          } else {
            this.$set(this.dataInfo, 'switchValue', false)
          }
        }
      })
    },
    // 删除计划
    deletePlan () {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      })
        .then(() => {
          removeTrStudyPlanApi([this.dataInfo.id]).then((res) => {
            this.$message.success('删除成功')
            this.$router.push({
              path: '/studyPlan'
            })
          })
        })
        .catch(() => {})
    },
    // 结束课程
    mastEndClass (status) {
      updateStatusApi({
        status,
        id: this.dataInfo.id
      }).then((res) => {
        if (res.code === 200) {
          this.getDetailInfo()
          this.$message.success('操作成功！')
        }
      })
    },
    _tabChange (value) {
      // 点击tab
      if (value.index == '4') {
        this.$refs.operationRecord.getOperationRecord()
      }
    },
    studyProcess () {
      // 学习进度
      this.$router.push({
        path: '/learningProgress',
        query: {
          studyPlanName: this.dataInfo.planName
        }
      })
    },
    examRecord () {
      // 考试安排

      this.$router.push({
        path: '/examRecord',
        query: {
          planName: this.dataInfo.planName
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-box {
  padding: 16px 24px 24px;
  background-color: #ffffff;
  border-radius: 4px;
  .top-box-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .top-box-info-left {
      display: flex;
      img {
        margin-right: 16px;
        width: 120px;
        height: 90px;
        border-radius: 4px;
      }
      .top-box-info-left-xx {
        .top-box-info-left-bt {
          margin: 4px 0;
          font-size: 16px;
          color: #333333;
          font-weight: 500;
          span {
            display: inline-block;
            padding: 3px 12px;
            margin-left: 16px;
            font-size: 12px;
            color: #ffffff;
            border-radius: 8px 0px 8px 0px;
            background: linear-gradient(to right, #31adf6, #5096ff);
          }
        }
        .top-box-info-left-kc {
          margin-bottom: 14px;
          font-size: 14px;
          color: #666666;
        }
        .top-box-info-left-mc {
          font-size: 12px;
          color: #979797;
        }
      }
    }
  }
}
/deep/.el-button {
  margin-left: 16px !important;
  font-size: 14px;
  height: 32px;
}
.bottom-box {
  margin-top: 16px;
  padding: 0 24px;
  background-color: #ffffff;
  border-radius: 4px;
  .bottom-tab-box {
    /deep/.el-tabs__nav {
      height: 55px;
    }
    /deep/.el-tabs__item {
      line-height: 55px;
    }
  }
}

</style>
