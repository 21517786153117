<!--
 * @Descripttion:
 * @version:
 * @Author: 张圳
 * @Date: 2023-11-15 16:09:21
 * @LastEditors: wenwen
 * @LastEditTime: 2024-03-15 15:06:28
-->
<template>
  <div>
    <div class="trainee-box">
      <div class="trainee-box-title">
        <com-title>受训学员</com-title>
        <div v-if="dataInfo.status!=2">
          <el-button   class="add-group-btn" :style="{'--lock-btn': $store.state.settings.theme}" @click="deleteStudent">移除学员</el-button>
          <el-button type="primary" @click="addStudent">添加学员</el-button>
        </div>
      </div>
      <el-form class="trainee-form" :model="searchForm" ref="searchForm" inline>
        <el-form-item label="部门">
          <GlobalTreeSelect
              style="width: 326px"
              v-model="searchForm.deptIdList"
              filterable
              clearable
              multiple
              :treeProps="defaultProps"
              :data="treeData"
            >
            </GlobalTreeSelect>
        </el-form-item>
        <el-form-item label="受训人员">
          <el-input
            v-model="searchForm.employeeName"
            clearable
            style="width: 300px"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-button @click="reset">重置</el-button>
        <el-button type="primary" @click="query">查询</el-button>
      </el-form>
      <el-table
        class="trainee-table"
        :data="tableData"
        @selection-change="_handleSelectionChange"
        row-key="id"
        ref="multipleTable"
        height="calc(100vh - 600px)"
      >
        <el-table-column :reserve-selection="true" type="selection" width="55"> </el-table-column>
        <el-table-column prop="employeeName" label="姓名"> </el-table-column>
        <el-table-column prop="deptName" label="部门">
          <template slot-scope="{row}">
            <span>{{row.deptName||'—'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="postName" label="岗位">
          <template slot-scope="{row}">
            <span>{{row.postName||'—'}}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="searchForm.currentPage"
            :page-size="searchForm.pageSize"
            layout="total, prev, pager, next"
            :total="searchForm.total"
          >
      </el-pagination>
      <SelectPersonnel
      v-if="personnelShow"
      :choosedKey="searchForm.employees"
      :personnelShow="personnelShow"
      @submitHandle="_submitPersonnel"
      title="选择人员"
    />
    </div>
  </div>
</template>

<script>
import comTitle from '@/components/comTitle/index.vue'
import GlobalTreeSelect from '@/components/globalTreeSelect/index.vue'
import { getManageFrameDeptApi } from '@/api/api'
import { getTraineeListApi, deleteStudentApi, addStudentApi } from '@/api/study'
import SelectPersonnel from '@/components/selectPersonnel/index.vue'
export default {
  props: {
    dataInfo: {
      default: () => {},
      type: Object
    }
  },
  components: {
    comTitle,
    GlobalTreeSelect,
    SelectPersonnel
  },
  data () {
    return {
      searchForm: {
        deptName: '',
        trainee: '',
        pageSize: 10,
        page: 1,
        total: 10,
        employees: []
      },
      tableData: [], // 列表数据
      defaultProps: {
        children: 'childList',
        label: 'name'
      },
      treeData: [], // 部门列表
      multipleTable: [], // 表格多选
      personnelShow: false
    }
  },
  created () {
    this.getTraineeList()
    this.getManageFrameDept()
  },
  methods: {
    query () {
      this.searchForm.page = 1
      this.getTraineeList()
    },
    getManageFrameDept () {
      // 获取团队
      getManageFrameDeptApi().then(res => {
        if (res.code === 200) {
          this.treeData = res.data
        }
      })
    },
    getTraineeList () {
      // 根据团队获取老师
      getTraineeListApi({
        studyPlanId: this.$route.query.id,
        ...this.searchForm
      }).then(res => {
        if (res.code === 200) {
          this.tableData = res.object.results
          this.searchForm.total = res.object.total
        }
      })
    },
    handleCurrentChange (val) {
      this.searchForm.page = val
      this.getTraineeList()
    },
    _handleSelectionChange (arr) {
      this.multipleTable = arr
    },
    deleteStudent () {
      // 移除学员
      if (!this.multipleTable.length) {
        this.$message.warning('请选择需要移除的学员')
        return
      }
      const ids = []
      this.multipleTable.forEach(item => {
        ids.push(item.id)
      })
      deleteStudentApi({
        ids,
        studyPlanId: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.getTraineeList()
          this.$message.success('操作成功！')
        }
      })
    },
    addStudent () {
      // 添加学员
      if (this.dataInfo.status == 1) {
        this.$message.warning('学习计划进行中不可以添加学员！')
        return
      }
      this.searchForm.employees = []
      this.personnelShow = true
    },
    _submitPersonnel (arr) {
      // 选择学员添加
      this.searchForm.employees = []
      arr.forEach(item => {
        this.searchForm.employees.push(item.userId)
      })
      addStudentApi({
        ...this.searchForm,
        studyPlanId: this.$route.query.id
      }
      ).then(res => {
        if (res.code === 200) {
          this.$message.success('操作成功！')
          this.personnelShow = false
          this.getTraineeList()
        }
      })
    },
    reset () {
      this.searchForm = {
        deptName: '',
        trainee: '',
        pageSize: 10,
        page: 1,
        total: 10,
        employees: []
      }
      this.getTraineeList()
    }
  }
}
</script>

<style lang="scss" scoped>
.trainee-box {
  height:  calc(100vh - 440px);
  overflow: auto;
}
.trainee-box-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
}
.trainee-form {
  margin-bottom: 32px;
  /deep/.el-form-item {
    margin-bottom: 0;
    margin-right: 32px;
  }
  /deep/.el-form-item__label {
    padding-right: 16px;
    color: rgba(0, 0, 0, 0.9);
  }
}
.trainee-table {
  /deep/th {
    height: 54px;
    background-color: #f6f7f9;
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
  }
  /deep/td {
    height: 54px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
  }
}
/deep/.el-pagination {
  text-align: right;
  margin-top: 10px;
}
.add-group-btn{
  width: 88px !important;
}
</style>
