<!--
 * @Descripttion:
 * @version:
 * @Author: 张圳
 * @Date: 2023-11-15 14:46:28
 * @LastEditors: wenwen
 * @LastEditTime: 2024-03-18 10:52:45
-->
<template>
  <div class="container-warp">
    <div class="detail-info-box" v-if="!editFlag">
      <div class="detail-info-box-title">
        <com-title>基本信息</com-title>
        <el-button type="primary" plain @click="_editBtn" v-if="dataInfo.status!=2">编辑</el-button>
      </div>
      <el-row>
        <el-col :span="8">
          <div class="com-div">
            <span class="com-label">计划名称</span>
            <span class="com-text">{{ dataInfo?.planName }}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="com-div">
            <span class="com-label">计划编号</span>
            <span class="com-text">{{ dataInfo?.studyPlanCode }}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="com-div">
            <span class="com-label">计划时间</span>
            <span class="com-text">{{ dataInfo?.startTime + ' - ' + dataInfo?.endTime }}</span>
          </div>
        </el-col>
      </el-row>
      <el-row>

        <el-col :span="8">
          <div class="com-div">
            <span class="com-label">计划类别</span>
            <span class="com-text">{{dataInfo?.typeValue || '-'}}</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="com-div">
            <span class="com-label">计划封面</span>
            <div class="img-box">
              <img v-if="!dataInfo.cover" src="@/assets/img/Course.png" alt="">
              <!-- <div v-for="(item,index) in dataInfo?.cover.split(',')" :key="index"> -->
                    <el-image
                    v-else
                      :src="dataInfo.cover"
                      :preview-src-list="[dataInfo.cover]">
                  </el-image>
                <!-- </div> -->
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="com-div">
            <span class="com-label">课程描述</span>
            <span class="com-text">{{ dataInfo?.description  || '-' }}</span>
        </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="com-div">
            <span class="com-label">课程介绍</span>
            <span class="com-text" v-html="dataInfo?.introduce"></span>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="edit-info-box" v-if="editFlag">
      <div class="detail-info-box-title" style="margin-bottom: 21px;">
        <com-title>基本信息</com-title>
      </div>
      <div class="edit-plan-box">
      <el-form :model="editForm" ref="editForm" :rules="rules" label-width="auto">
        <el-row>
          <el-col :span="8" style="padding-right:32px;">
            <el-form-item label="计划名称" prop="planName">
              <el-input
              clearable
                v-model="editForm.planName"
                placeholder="请输入计划名称"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="padding-right:32px;">
            <el-form-item label="计划编号" prop="studyPlanCode">
              <el-input
              clearable
                v-model="editForm.studyPlanCode"
                placeholder="请输入计划编号"
                style="width: 100%"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">

            <el-form-item label="计划时间" prop="planTime">
              <el-date-picker
              clearable
                v-model="editForm.planTime"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                style="width: 100%"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>

          <el-col :span="8" style="padding-right:32px;">
            <el-form-item label="计划类型" prop="type">
              <el-select clearable style="width: 100%" v-model="editForm.type">
                <el-option
                  v-for="item in planTypeList"
                  :key="item.id"
                  :label="item.categoryName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" >
            <el-form-item label="计划封面">
              <el-upload
                name="files"
                ref="upload"
                drag
                multiple
                :limit="1"
                :action="uploadAction"
                :headers="uploadHeader"
                list-type="picture-card"
                :on-success="_handeSuccessImage"
                :on-change="_uploadCoverImage"
                :on-remove="_handleRemove"
                :on-preview="_preview"
                :file-list="imgList"
                :class="imgList.length>=1?'disabled':''"
                :style="[
                  { '--uploadBgColor': uploadBgColor },
                  { '--uploadBorderColor': $store.state.settings.theme },
                ]"
              >
                <div class="upload-box">
                  <i
                    class="iconfont icon-icon_new"
                    :style="{ color: $store.state.settings.theme }"
                  ></i>
                  <el-button type="text" style="margin-left: 0 !important"
                    >上传封面</el-button
                  >
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16" style="padding-right:32px;">
            <el-form-item label="计划描述" prop="description">
              <el-input
              clearable
                type="textarea"
                autosize
                v-model="editForm.description"
                placeholder="请输入计划描述">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16" style="padding-right:32px;">
            <el-form-item label="计划介绍" prop="introduce">
              <RichTextEditor
                  class="editor"
                  ref="richTextEditor"
                  :content="editForm.introduce"
                  @changeData="_getRichTextHtml"
                />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="footer-box">
        <el-button @click="_cancelBtn">取消</el-button>
        <el-button type="primary" @click="submitForm">保存</el-button>
      </div>
    </div>
    </div>
    <!-- 列表预览 -->
     <!-- 大图预览 -->
     <el-image-viewer
      v-if="dialogVisible"
      :on-close="closeImgViewer"
      :url-list="imagePreviewUrls"
      :z-index="3000"
    />
  </div>
</template>

<script>
import comTitle from '@/components/comTitle/index.vue'
import { getCookie } from '@/utils/util'
import { editPlanDetailApi } from '@/api/study.js'
import { getFindCustomizeTypeApi } from '@/api/settings'
import RichTextEditor from '@/components/richTextEditor/index.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  components: {
    comTitle,
    RichTextEditor,
    ElImageViewer
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      editFlag: false,
      uploadAction: process.env.VUE_APP_BASE_API_IMAGE + '/oss-service/ossObject/upload', // 上传图片路径
      uploadHeader: {
        token: getCookie('token')
      },
      editForm: {
        planName: '',
        studystudyPlanCode: '',
        startTime: '',
        endTime: '',
        type: '',
        cover: '',
        description: '',
        introduce: '',
        planTime: []
      },
      rules: {
        planName: [{ required: true, message: '请输入计划名称', trigger: 'blur' }],
        studyPlanCode: [{ required: true, message: '请输入计划编号', trigger: 'blur' }],
        planTime: [{ required: true, message: '请选择计划时间', trigger: 'change' }]
      },
      planTypeList: [], // 类型下拉选
      imgList: [],
      uploadBgColor: '',
      dialogVisible: false, // 图片预览
      imagePreviewUrls: []
    }
  },
  created () {
    this._getPlanTypeList()
  },
  mounted () {
    this.uploadBgColor = this._hexToRgb(this.$store.state.settings.theme)
  },
  methods: {
    _getRichTextHtml (html) {
      this.editForm.introduce = html
    },
    // 获取计划类型数据
    _getPlanTypeList () {
      getFindCustomizeTypeApi({
        type: 1
      }).then(res => {
        if (res.code === 200) {
          this.planTypeList = res.data.planType
        }
      })
    },
    // 点击编辑
    _editBtn () {
      this.editForm = JSON.parse(JSON.stringify(this.dataInfo))
      this.$set(this.editForm, 'planTime', [this.editForm.startTime, this.editForm.endTime])
      if (this.editForm.cover) {
        this.imgList = this.editForm.cover && this.editForm.cover.split(',').map(item => {
          return {
            url: item
          }
        })
      } else {
        this.imgList = []
      }
      this.planTypeList.forEach(item => {
        if (item.id !== this.editForm.type) {
          this.editForm.type = null
        }
      })
      this.editFlag = true
    },
    // 学习
    _cancelBtn () {
      this.editFlag = false
    },
    submitForm () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.editForm.startTime = this.editForm.planTime ? this.editForm.planTime[0] : null
          this.editForm.endTime = this.editForm.planTime ? this.editForm.planTime[1] : null
          editPlanDetailApi({ ...this.editForm }).then(res => {
            if (res.code === 200) {
              this.editFlag = false
              this.$emit('updatedDetail')
              this._getPlanTypeList()
              this.$message.success('操作成功！')
            }
          })
        }
      })
    },
    // 图片上传成功
    _handeSuccessImage (response, file, fileList) {
      this.imgList = fileList
      const arr = []
      if (fileList.length > 0) {
        fileList.forEach(item => {
          if (item.response) {
            arr.push(item.response.data[0].url)
          } else {
            arr.push(item.url)
          }
        })
      }
      this.editForm.cover = arr.join(',')
    },
    // 上传封面图触发
    _uploadCoverImage (file, fileList) {

    },
    // 删除图片
    _handleRemove (file, fileList) {
      this.imgList = fileList
      let arr = []
      if (fileList.length > 0) {
        fileList.forEach(item => {
          arr.push(item.response.data[0].url)
        })
      } else {
        arr = []
      }
      this.editForm.cover = arr.join(',')
    },
    closeImgViewer () {
      this.dialogVisible = false
    },
    _preview (file) {
      // 图片预览
      this.dialogVisible = true
      this.imagePreviewUrls = file.url ? [file.url] : [file.response && file.response.data[0].url]
    },
    // 悬浮颜色
    _hexToRgb (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      this.$forceUpdate()
      return result
        ? 'rgba' +
            '(' +
            parseInt(result[1], 16) +
            ',' +
            parseInt(result[2], 16) +
            ',' +
            parseInt(result[3], 16) +
            ',' +
            0.2 +
            ')'
        : null
    }
  }
}
</script>

<style lang='scss' scoped>
  .detail-info-box{
    padding-bottom: 203px;
    .detail-info-box-title{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .com-div{
    display: flex;
    margin-top: 16px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.90);
    .img-box{
      display: flex;
      flex-wrap: wrap;
      img{
        width: 90px;
        height: 90px;
      }
    }
  }
  .com-label{
    margin-right: 16px;
    color: #999999;
  }
  /deep/.el-upload--picture-card{
    background: var(--uploadBgColor) !important;
    border-color: var(--uploadBorderColor) !important;
    width: 90px;
    height: 90px;
    line-height: 20px;
    .upload-box {
      display: flex;
      flex-direction: column;
      margin-top: 22px;
      .iconfont {
        font-size: 17px;
      }
    }
  }
  /deep/.el-upload-dragger{
    width: 90px !important;
    height: 90px !important;
    background-color: transparent;
    border: 1px dashed transparent;
  }
  /deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 90px;
    height: 90px;
  }
  /deep/.el-upload-list--picture-card .el-progress {
    width: 90px !important;
  }
  /deep/.el-progress-circle {
    width: 90px !important;
    height: 90px !important;
  }
  /deep/.el-form-item__label{
    color:  rgba(0, 0, 0, 0.90);
  }
  /deep/.ql-container{
    min-height: 122px;
  }
  .footer-box{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 217px;
    /deep/.el-button{
      margin-left: 16px !important;
      font-size: 14px;
      height: 32px;
      }
  }
  /deep/.el-image{
    width: 90px;
    height: 90px;
    margin-right: 10px;
  }
  .edit-info-box{
  height:  calc(100vh - 440px);
  overflow: auto;
}
/deep/.disabled .el-upload--picture-card {
    display: none !important;
}

/deep/.disabled .el-button--success.is-plain {
    display: none !important;
}
</style>
