<!--
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2024-02-22 17:39:44
 * @LastEditors: wenwen
 * @LastEditTime: 2024-03-14 16:41:59
-->
<template>
  <div>
    <div class="set-box">
      <div class="set-box-title">
        <com-title>操作记录</com-title>
      </div>
      <div class="content">
        <div class="empty-box" v-if="openerList.length === 0">
            <PlaceholderMap text="暂无操作记录噢~" />
          </div>
        <el-steps direction="vertical">
            <el-step v-for="(item,index) in openerList" :key="index" :title="item.content||'—'" :description="item.createTime||'—'"></el-step>
        </el-steps>
      </div>
    </div>
  </div>
</template>

<script>
import comTitle from '@/components/comTitle/index.vue'
import { getOperationRecordApi } from '@/api/course'
import PlaceholderMap from '@/components/placeholderMap/index.vue'
export default {
  components: {
    comTitle,
    PlaceholderMap
  },
  data () {
    return {
      openerList: []
    }
  },
  created () {
    this.getOperationRecord()
  },
  methods: {
    getOperationRecord () {
      getOperationRecordApi({
        businessId: this.$route.query.id,
        businessType: 1
      }).then(res => {
        if (res.code === 200) {
          this.openerList = res.data
          this.$nextTick(() => {
            var x = document.querySelectorAll('.el-step__icon-inner')
            x.forEach((item, index) => {
              item.innerHTML = x.length - index
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.set-box{
  height:  calc(100vh - 440px);
  overflow: hidden;
  padding-bottom: 10px;
}
.content{
    padding: 0;
    margin-top: 20px;
}
/deep/ .el-step__title{
    color: #48494D !important;
    font-weight: 500;
}
/deep/.el-step__description{
    margin-bottom: 36px;
    color: #A8ABB2;
}
/deep/.el-step {
  flex-basis: 17% !important;
}
</style>
